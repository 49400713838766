import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadManufacturers, getManufacturers, addManufacturer } from "../../../store/manufacturer-reducer";
import { showLoading, hideLoading, delayedHideLoading } from '../../../store/loading-reducer';
import { AddManufacturerModal } from "./addManufacturerModal"
import { Link } from 'react-router-dom';
import { updateTitle } from '../../../helpers/titleHelper';
import { deleteManufacturers, downloadPriceList } from '../../../api/manufacturersapi';
import { DeleteConfrimationModal } from '../../../components/admin/DeleteConfirmationModal'

export function Manufacturers() {
    updateTitle('Admin', 'Manufacturers');
    const dispatch = useDispatch();
    const manufacturers = useSelector(getManufacturers);
    const [showAddModal, setShowAddModal] = useState(false);
    useEffect(() => {
        dispatch(showLoading());
        dispatch(loadManufacturers())
            .then(() => {
                dispatch(delayedHideLoading());
            })
            .catch(() => {
                dispatch(hideLoading());
            });

    }, [dispatch]);


    const handleAddManufacturer = (manufacturerData, onSuccess, onError) => {
        dispatch(addManufacturer(manufacturerData, onSuccess, onError));
    };

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [manufacturerModel, setManufacturerModel] = useState({ id: 0, title: '' });

    const handleDeleteTrigger = (id, name) => {
        setManufacturerModel({ id: id, title: name });
        setShowDeleteModal(true);
    }

    const handleDeleteManufacturer = (manufacturerData) => {
        deleteManufacturers(manufacturerData.id).then(response => {
            if (response.data.success == true) {
                dispatch(loadManufacturers())
                    .then(() => {
                        dispatch(delayedHideLoading());
                    })
                    .catch(() => {
                        dispatch(hideLoading());
                    });
            }
        });
    };

    const handleDownloadPriceList = async () => {
        await downloadPriceList().then(response => {
            if (response && response.status === 200 && response.data) { 
                const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'ManufacturersProductsPriceList.xlsx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                console.error("Failed to download file: Invalid response from server.");
            }
        });
    };

    return (
        <div className="admin-page-wrap">
            <div className="admin-top-bar-wrap">
                <div className="admin-top-bar-left">
                    <Link className="admin-top-bar-back" to="/admin/">
                        Back
                    </Link>
                </div>
            </div>
            <div className="admin-page-content">
                <div className="admin-page-title-wrap">
                    <h1 className="admin-page-heading">Manufacturers &amp; Products</h1>
                    <button className="admin-button power" onClick={() => setShowAddModal(true)}>Add Manufacturer</button>
                    <AddManufacturerModal
                        show={showAddModal}
                        onClose={() => setShowAddModal(false)}
                        handleAddManufacturer={handleAddManufacturer}
                    />
                </div> 
                <DeleteConfrimationModal
                    show={showDeleteModal}
                    onClose={() => setShowDeleteModal(false)}
                    handleDeleteEvent={handleDeleteManufacturer}
                    model={manufacturerModel}
                    label={"manufacturer"}
                />
                <div>
                    <button className="admin-button power" onClick={() => handleDownloadPriceList()}>Download Price List</button>
                    <button className="admin-section-title-action">Import / Export (Coming Soon...)</button>
                    <div className="small-space">&nbsp;</div>
                </div>
                <div className="admin-section">
                    {manufacturers.data && manufacturers.data.length > 0 ? (
                        manufacturers.data.map((manufacturer) => (
                            <div className="admin-tall-list-item" key={manufacturer.id}>
                                <p className="admin-tall-list-name">{manufacturer.name}</p>
                                <div className="admin-tall-list-options">
                                    <Link to={`/admin/manufacturers-products/edit/${manufacturer.id}`} className="admin-tall-list-edit">
                                        <span className="material-symbols-rounded">edit</span>
                                    </Link>

                                    <button
                                        className="admin-tall-list-edit"
                                        onClick={() => handleDeleteTrigger(manufacturer.id, manufacturer.name)}
                                    >
                                        <span className="material-symbols-rounded">delete</span>
                                    </button>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="admin-tall-list-item">
                            <p className="admin-tall-list-name">There are not any active Manufacturers in the system.</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
