import api from '../utils/api';

export const getAll = (config = {}) => {
    const { options } = config;
    return api({
        url: '/api/manufacturers/get-all',
        method: 'GET',
        ...options,
    });
};
export const edit = (config = {}) => {
    const { options } = config;
    return api({
        url: '/api/manufacturers/edit',
        method: 'POST',
        ...options,
    });
};
export const add = (config = {}) => {
    const { options } = config;
    return api({
        url: '/api/manufacturers/add',
        method: 'POST',
        ...options,
    });
};
export const downloadPriceList = (config = {}) => {
    const { options } = config; 
    return api.post(`/api/manufacturers/download-price-list`, options, {
        responseType: 'blob', 
    });
}; 
export const deleteManufacturers = (config = {}) => {
    return api.delete('/api/manufacturers/delete/' + config);
};


